import React from 'react'
import { useFetch } from '../../tools/useFetch'
import Card, { Title } from '../../styles/Card'
import { LoadingHigh } from '../../loading'
import ErrorText from '../../basic/error'
import SendButton from './SendButton'

const PaymentNotify = () => {
  const { data, fetching, success, error } = useFetch('admin/notify')

  const renderMail = (mail, index) => {
    return (
      <Card mt padding fullWidth key={index}>
        <Title>{mail.recipient}</Title>
        <div dangerouslySetInnerHTML={{ __html: mail.text }} />
      </Card>
    )
  }
  if (success) {
    return (
      <>
      <Card padding mt fullWidth important>
          <Title>Betalings notificationer</Title>
          <p>Send e-mail ud til alle brugeren om at der snart skal betales kontingent.</p>
          <p>Husk at kontrollere om datoen er rigtig i e-mailen.</p>
          <p>Send knappen er i bunden af siden.</p>
      </Card>
        {data.map((mail, index) => renderMail(mail, index))}
        <SendButton numberOfMails={data.length} />
      </>
    )
  } else if (fetching) {
    return <LoadingHigh />
  } else {
    return <ErrorText>{error && error.message}</ErrorText>
  }
}

export default PaymentNotify
