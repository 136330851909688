import request from 'components/tools/request'
import React, { useState, FC } from 'react'
import { MdMoreVert } from 'react-icons/md'
import styled from 'styled-components'

import EditAttendee from './EditAttendee'

const Tr = styled.tr`
  height: 2.5em;
  background-color: #fff;
  color: ${(p) => (p.unapproved ? 'orange' : 'default')};
  :nth-child(even) {
    background-color: #f2f2f2;
  }
  border-bottom: solid 1px #e1e1e1;
  cursor: pointer;
`

const Td = styled.td`
  white-space: nowrap;
  padding: 0 10px;
`
const TdShort = styled.td`
  padding: 0px;
  width: 10px;
`
const Tdd = styled.td`
  padding: 0 20px;
`
const WEEK_IN_MS = 6.04e8
const ALLOWED_NUMBER_OF_WEEKS = 3

const Attendee: FC<{
  planId: number
  uid: string
  name: string
  createdAt?: string
  approved?: boolean
  isMember: boolean
  slug: string
  dateString: string
  increase: () => void
  decrease: () => void
  attended: boolean
}> = ({
  isMember,
  planId,
  uid,
  name = '',
  createdAt,
  approved,
  dateString,
  slug,
  increase,
  decrease,
  attended,
}) => {
  const [status, setStatus] = useState<boolean>(attended)

  const [showEdit, setShowEdit] = useState(false)

  const isApproved = () => {
    if (approved === true) return true
    if (createdAt == null) return true
    //After three weeks the user becomes yellow
    return (
      new Date(createdAt).getTime() + WEEK_IN_MS * ALLOWED_NUMBER_OF_WEEKS >
      Date.now()
    )
  }

  const remove = async () => {
    try {
      setStatus(false)
      decrease()
      await request.del(`admin/attendees/${planId}/${dateString}/${uid}`)
    } catch (error) {
      setStatus(true)
      increase()

      console.log(error)
    }
  }

  const add = async () => {
    try {
      setStatus(true)
      increase()
      await request.post(`admin/attendees/${planId}/${dateString}/${uid}`)
    } catch (error) {
      decrease()
      setStatus(false)
      console.log(error)
    }
  }

  const toggle = async () => {
    if (status) {
      await remove()
    } else {
      await add()
    }
  }

  const handleEdit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowEdit(true)
  }

  return (
    <>
      <Tr
        onClick={toggle}
        onContextMenu={handleEdit}
        unapproved={!isApproved()}
      >
        {!isMember ? (
          <TdShort onClick={handleEdit}>
            <MdMoreVert />
          </TdShort>
        ) : (
          <Td />
        )}
        <Td>{name}</Td>
        <Tdd>{status ? 'x' : ''}</Tdd>
      </Tr>
      {showEdit && (
        <EditAttendee
          user={{ name, createdAt, uid, approved }}
          onClose={() => setShowEdit(false)}
          dataKey={`admin/attendees/${slug}/${dateString}`}
        />
      )}
    </>
  )
}

export default Attendee
