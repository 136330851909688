import React from 'react'
import useCall from '../../tools/useCall'
import Button from '../../styles/Button'
import ErrorText from '../../basic/error'
import styled from 'styled-components'

const Text = styled.h3`
  text-align: center;
`

const ErrorMessage = styled.p`
  font-style: italic;
`

const SendButton = ({ numberOfMails }) => {
  const { call, inProgress, resp, success, error } = useCall('admin/notify')

  const renderErrors = errors => (
    <>
      <Text>Der blev registreret følgende fejl:</Text>
      <div>
        {errors.map((e, index) => (
          <ErrorMessage key={index}>{e.Message}</ErrorMessage>
        ))}
      </div>
      <Text>Alle andre har modtaget mailen.</Text>
    </>
  )

  return (
    <div>
      <ErrorText>{error && error.message}</ErrorText>
      {success ? (
        <>
          <Text>Sent {resp.numberOfSentEmails} e-mails.</Text>
          {resp.errors.length && renderErrors(resp.errors)}
        </>
      ) : (
        <Button center mt loading={inProgress} onClick={() => call()}>
          Send ({numberOfMails} mails)
        </Button>
      )}
    </div>
  )
}

export default SendButton
