import AttendeeTeamOverview, { AttendanceList, AttendeeList } from 'components/Admin/AttendeeList'
import MySubscriptions from 'components/Profile/MySubscriptions'
import React from 'react'
import FailedCharges from './components/Admin/insights/FailedCharges'
import EventOverview from './components/events/Overview'
import EventPage from './components/events/Single'
import Info from './components/footer-info/Info'
import InfoPage from './components/footer-info/InfoPage'
import FrontPage from './components/Frontpage'
import FrontPageV3 from './components/Frontpage/v3'
import FrontPageV4 from './components/Frontpage/v4'
import EditArticlePage from './components/news/CreateUpdateArticle'
import NewArticlePage from './components/news/CreateUpdateArticle'
import NewsPage from './components/news/NewsFeed'
import SingleArticlePage from './components/news/SingleArticlePage'
import DetailPage from './components/plans/DetailPage'
import TeamsOverview from './components/plans/OverviewPage'
import Profile from './components/Profile/'
import ResetPassword from './components/userManagement/ResetPassword'
import VerifyEmail from './components/userManagement/VerifyEmail'
import PaymentNotify from './components/Admin/paymentNotify'

const Admin = React.lazy(() => import('components/Admin'))
const Users = React.lazy(() => import('components/Admin/Users'))
const Mail = React.lazy(() => import('components/Admin/MailV2'))
const EditInfoPage = React.lazy(() => import('./components/footer-info/EditInfoPage'))
const RoleManagement = React.lazy(() => import('./components/Admin/RoleManagement'))

export default {
  '/': () => <FrontPage />,
  '/v3': () => <FrontPageV3 />,
  '/v4': () => <FrontPageV4 />,
  '/news': () => <NewsPage />,
  '/news/new': () => <NewArticlePage />,
  '/news/:slug': ({ slug }) => <SingleArticlePage slug={slug} />,
  '/news/:slug/edit': ({ slug }) => <EditArticlePage slug={slug} />,

  '/teams': () => <TeamsOverview />,
  '/teams/:slug': ({ slug }) => <DetailPage slug={slug} />,

  '/events': () => <EventOverview />,
  '/events/:slug': ({ slug }) => <EventPage slug={slug} />,

  '/profile': () => <Profile />,
  '/profile/subscriptions': () => <MySubscriptions />,
  '/verify/:token': ({ token }) => <VerifyEmail token={token} />,
  '/reset/:token': ({ token }) => <ResetPassword token={token} />,

  '/info': () => <Info />,
  '/info/:slug': ({ slug }) => <InfoPage slug={slug} />,
  '/info/:slug/edit': ({ slug }) => <EditInfoPage slug={slug} />,
  '/info/:slug/new': ({ slug }) => <EditInfoPage slug={slug} isNew />,


  '/admin': () => <Admin />,
  '/admin/notify': () => <PaymentNotify />,
  '/admin/users': () => <Users />,
  '/admin/mail': () => <Mail />,
  '/admin/failedcharges': () => <FailedCharges />,
  '/admin/roles': () => <RoleManagement />,
  '/admin/attendees': () => <AttendeeTeamOverview />,
  '/admin/attendees/:slug': ({ slug }) => <AttendeeList slug={slug} />,
  '/admin/attendees/:slug/attendance': ({ slug }) => <AttendanceList slug={slug} />,
}
